import styled from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  getTypography,
  pxToCssFont,
  pxToCssFontSize,
  pxToRem,
} from '@vfit/shared/themes';

export const Category = styled.div<{ headerShort: boolean | undefined }>`
  font-family: ${fonts.regular};
  max-width: 70%;
  margin-bottom: ${({ headerShort }) => (headerShort ? '8px' : '10px')};
  ${pxToCssFont(18, 24)}
  font-weight: 400;
  color: ${colors.$f2f2f2};

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 24px;
  }
`;

export const Title = styled.div<{ headerShort: boolean | undefined }>`
  max-width: 70%;
  margin-bottom: 24px;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(42, 40)}
  color: ${colors.$ffffff};

  p {
    margin: inherit;
  }

  @media (min-width: ${breakpoints.tablet}) {
    h2 {
      ${pxToCssFont(18, 24)}
    }

    ${pxToCssFont(64, 80)}
    p {
      margin: inherit;
    }

    h4 {
      ${pxToCssFont(30, 38)}
    }

    h5 {
      ${pxToCssFont(20, 30)}
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(70, 65)}
    p {
      margin: inherit;
    }

    h4 {
      ${pxToCssFont(42, 52)}
    }

    h5 {
      ${pxToCssFont(20, 30)}
    }
  }
`;

export const TitleLite = styled.div<{ headerShort: boolean | undefined }>`
  ${getTypography('h4.extrabold')}
  color: ${colors.$262626};

  p {
    margin: inherit;
  }

  @media (min-width: ${breakpoints.tablet}) {
    h2 {
      ${getTypography('h4.extrabold')}
      color: ${colors.$262626};
    }

    p {
      margin: inherit;
      ${getTypography('h4.extrabold')}
      color: ${colors.$262626};
    }

    h4 {
      ${getTypography('h4.extrabold')}
      color: ${colors.$262626};
    }

    h5 {
      ${getTypography('h4.extrabold')}
      color: ${colors.$262626};
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    p {
      ${getTypography('h4.extrabold')}
      margin: inherit;
    }

    h4 {
      ${getTypography('h4.extrabold')}
    }

    h5 {
      ${getTypography('h4.extrabold')}
    }
  }
`;

export const Description = styled.div<{ headerShort: boolean | undefined }>`
  font-family: ${fonts.regular};
  max-width: 70%;
  margin-bottom: ${({ headerShort }) => (headerShort ? '32px' : '35px')};
  ${pxToCssFont(18, 24)}
  font-weight: 400;
  color: ${colors.$f2f2f2};

  span {
    display: flex;
    font-weight: 400;
    color: ${colors.$ffffff};
    align-items: baseline;
  }

  @media (min-width: ${breakpoints.tablet}) {
    h2 {
      ${pxToCssFont(18, 24)}
    }
  }
`;

export const DescriptionLite = styled.div<{ headerShort: boolean | undefined }>`
  ${getTypography('h4.light')}
  color: ${colors.$262626};

  p {
    margin: inherit;
  }

  @media (min-width: ${breakpoints.tablet}) {
    h2 {
      ${getTypography('h4.light')}
      color: ${colors.$262626};
    }

    p {
      margin: inherit;
      ${getTypography('h4.light')}
      color: ${colors.$262626};
    }

    h4 {
      ${getTypography('h4.light')}
      color: ${colors.$262626};
    }

    h5 {
      ${getTypography('h4.light')}
      color: ${colors.$262626};
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    p {
      ${getTypography('h4.light')}
      margin: inherit;
    }

    h4 {
      ${getTypography('h4.light')}
    }

    h5 {
      ${getTypography('h4.light')}
    }
  }
`;

export const SlideProductContainer = styled.div<{ shortHero?: boolean; haveDivider: boolean }>`
  position: relative;
  margin-bottom: ${({ haveDivider }) => (!haveDivider ? '0px' : '50px')};
  min-height: ${({ shortHero }) => (shortHero ? '320px' : '442px')};

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${({ haveDivider }) => (!haveDivider ? '0px' : '80px')};
    min-height: ${({ shortHero }) => (shortHero ? '480px' : '800px')};
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-height: ${({ shortHero }) => (shortHero ? '480px' : '680px')};
  }
`;

export const DivImgContainer = styled.div<{ isApp?: boolean; absolute: boolean }>`
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  border-radius: ${(props) => (props.isApp ? '0 0 12px 12px' : '0 0 20px 20px')};
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: ${(props) => (props.absolute ? 0 : '')};
  left: ${(props) => (props.absolute ? 0 : '')};

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 0 0 30px 30px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    border-radius: 0 0 30px 30px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(1.14deg, rgba(0, 0, 0, 4%) 11.28%, rgba(0, 0, 0, 0%) 75.72%);
  }
`;

export const SlideProductNavigation = styled.div<{ shortHero: boolean }>`
  margin-bottom: ${({ shortHero }) => (shortHero ? '32px' : '22px')};

  display: flex;
  .back {
    width: inherit;
    display: flex;
    cursor: pointer;
    margin: 0;
    appearance: none;
    background: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
  }

  div {
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 9px;
      height: 16px;

      path {
        stroke: white;
      }
    }
  }

  span {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFontSize(16)}
    color: white;
    margin-left: 15px;
  }

  button {
    border: 0;
    display: flex;
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${({ shortHero }) => (shortHero ? '56px' : '22px')};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: ${({ shortHero }) => (shortHero ? '40px' : '22px')};
  }
`;

export const SlideProductContent = styled.div<{
  shortHero: boolean | undefined;
  headerLite: boolean;
}>`
  margin-left: 32px;
  padding-bottom: 32px;
  padding-top: ${({ shortHero, headerLite }) =>
    shortHero ? `${headerLite ? '80px' : '32px'}` : `${headerLite ? '80px' : '32px'}`};
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: ${({ shortHero, headerLite }) =>
      shortHero ? `${headerLite ? '112px' : '64px'}` : `${headerLite ? '112px' : '64px'}`};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 80px;
  }
`;

export const SlideProductContentTop = styled.div`
  width: 100%;
  flex: 0.8;

  @media (min-width: ${breakpoints.tablet}) {
    flex: 0.9;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex: 0.8;
  }
`;

export const SlideProductContentBottom = styled.div`
  position: absolute;
  display: flex;
  bottom: 23px;
  width: 100%;
  flex: 0.2;
  padding-bottom: 60px;
  justify-content: center;
  z-index: 10;
  margin-left: -32px;

  @media (min-width: ${breakpoints.tablet}) {
    padding-bottom: 0;
    margin-left: -32px;
    flex: 0.1;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-bottom: 10px;
    margin-left: -80px;
  }
`;

export const SlideProductText = styled.div`
  h2 {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(16, 24)}
    letter-spacing: ${pxToRem(0.5)};
    text-transform: uppercase;
    color: ${colors.$ffffff};
    margin: 0 30px;
  }

  h4 {
    font-family: ${fonts.exbold};
    ${pxToCssFont(24, 28)}
    font-weight: 400;
    color: ${colors.$f2f2f2};
    margin: 30px 30px 0;
    max-width: none;
  }

  h5 {
    font-family: ${fonts.regular};
    ${pxToCssFont(18, 24)}
    font-weight: 700;
    color: ${colors.$bebebe};
    margin: 8px 30px 0;
    max-width: none;
  }

  @media (min-width: ${breakpoints.tablet}) {
    h2 {
      ${pxToCssFont(18, 24)}
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    h2 {
      ${pxToCssFont(18, 24)}
    }
  }
`;

export const SlideProductLiteText = styled.div`
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
`;

export const ModalTitle = styled.div`
  div {
    margin: 0;
    margin-bottom: 24px;
  }
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;

export const SlideButtonsContainer = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: column;

  button,
  a {
    min-width: 210px;
    width: auto;
    margin-bottom: 10px;
  }
  @media (min-width: ${breakpoints.tablet}) {
    gap: 20px;
  }
  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
  }
  gap: 15px;
`;
